/* eslint-disable */
import JsPDF from 'jspdf'
import { ENV_APP } from '../../../env'
import { baseBoard } from './core/baseboard'

/* disable eslint */
import 'jspdf-autotable'

function checkAddPage(doc, cursor, area, config) {
  const { heightLimit, margin, tableLimit, line, startX } = config

  if (cursor + area > heightLimit) {
    doc.addPage()
    cursor = margin
    // doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  return cursor
}

function header(doc, config, relatorio) {
  const logo1 = new Image()
  const logo2 = new Image()

  logo1.src = config.logo

  doc.addImage(logo1, 'PNG', config.margin, config.margin, 70, 55)

  if (relatorio.logoagroindustria) {
    logo2.src = relatorio.logoagroindustria
    doc.addImage(logo2, 'PNG', config.tableLimit - 50, config.margin, 73, 50)
  }

  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  const widthOfString = doc.getTextWidth('Diagnóstico Geral')

  doc.text(
    'Diagnóstico Geral',
    config.tableLimit / 2 - widthOfString / 2 + config.margin,
    config.margin + 30,
  )

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')
}

function subHeader(doc, _ref, _ref2) {
  const {
    fazenda: {
      produtor: { Nome: produtor },
      pessoajuridica: { NomeFantasia: fazenda },
      producer: { Matricula: matricula },
    },
    consultor: {
      pessoafisica: { Nome: consultor },
    },
    cidadefazenda,
    horaInicio,
    horaTermino,
    NumeroVisita,
  } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
    mediumFontSize,
    fontSize,
  } = _ref2

  const dataAtendimento = _ref.dataAtendimento

  doc.setFillColor(lineColor)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Informações básicas', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text('Fazenda:', startX + smallGutterX, startY + smallGutterY + boxHeight)

  doc
    .setFont('helvetica', 'bold')
    .text(fazenda, startX + smallGutterX, startY + gutterY + boxHeight)
    .setFont('helvetica', 'normal')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.text(
    'Consultor(a):',
    startX + smallGutterX,
    startY + smallGutterY + boxHeight * 2,
  )

  doc.text(consultor, startX + smallGutterX, startY + gutterY + boxHeight * 2)

  doc.text(
    'Matrícula:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + smallGutterY + boxHeight,
  )

  doc.text(
    matricula ? matricula : '-',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + gutterY + boxHeight,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')
  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')
  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Produtor(a):',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )
  doc.text(
    produtor.substring(0, 25),
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 3,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Cidade:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    cidadefazenda ? cidadefazenda.Nome : '-',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Data:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    dataAtendimento || '-',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Horário do início:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    horaInicio || '-',
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 3,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do término:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    horaTermino || ' - ',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número da visita:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${NumeroVisita}ª visita`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )
  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F')
}

function renderDocument(doc, config, report, cursor, layout) {
  const {
    smallBoxHeight,
    boxHeight,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
    startY,
  } = config

  cursor = startY + 10

  layout.forEach(item => {
    renderSectionHeader(doc, item.title, startX, cursor, mediumFontSize)
    cursor += 30

    doc.setFont('helvetica', 'normal').setFontSize(fontSize)

    item.lines.forEach(line => {
      if (line.columns && line.columns.length > 0) {
        line.columns.forEach(field => {
          cursor = checkAddPage(doc, cursor, 60, config)
          cursor = renderComponent({
            doc,
            cursor,
            startX,
            tableLimit,
            field,
            report,
            config,
          })
        })
      }
    })
  })

  return cursor
}

function renderSectionHeader(doc, title, startX, cursor, fontSize) {
  doc
    .setFont('helvetica', 'bold')
    .setFontSize(fontSize)
    .text(title, startX, cursor + 25)
    .setFont('helvetica', 'normal')
}

function simpleInput({ doc, cursor, startX, tableLimit, field, report }) {
  const text = field.label + report[field.key]
  cursor += drawBox(doc, text, startX, cursor, tableLimit, 20)
  // cursor += 20
  return cursor
}

function renderComponent({
  doc,
  cursor,
  startX,
  tableLimit,
  field,
  report,
  config,
}) {
  if (!field.key) {
    return renderComponent({
      doc,
      cursor,
      startX,
      tableLimit,
      field: field.columns,
      report,
    })
  }

  switch (field.type) {
    case 'selection':
      if (report[field.key] === '1') {
        cursor = drawColumn({
          doc,
          column: field,
          cursor,
          startX,
          report,
          tableLimit,
          config,
        })
      }
      break
    case 'simpleInput':
      if (report[field.key]) {
        cursor = simpleInput({ doc, cursor, startX, tableLimit, field, report })
      }
      break

    case 'multiInsertForm':
      const values = JSON.parse(report[field.key])
      const headers = field.linesLabels

      const valuesArray = values
        .map(value => {
          return [...Object.values(value)]
        })
        ?.sort((a, b) => a[0] - b[0])

      if (!values) return

      const table = []
      table.push(headers)

      cursor = checkAddPage(doc, cursor, 60, config)
      cursor += 20

      // add field label
      doc.setFont('helvetica', 'bold').setFontSize(10)
      doc.text(field.label, startX, cursor)

      cursor += 5

      doc.autoTable({
        head: [headers],
        body: valuesArray,
        startY: cursor,
        margin: { top: 10, bottom: 20, left: 25, right: 25 },

        styles: {
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
        },
        theme: 'plain',
      })

      cursor += valuesArray.length * 20 + 30

      break

    default:
      if (report[field.key] && report[field.key] !== '0') {
        const text = field.label + report[field.key]
        drawBox(doc, text, startX, cursor, tableLimit, 20)
        cursor += 20
      }
  }

  return cursor
}

function drawColumn({
  doc,
  column,
  cursor,
  startX,
  report,
  tableLimit,
  config,
}) {
  if (!report) return cursor

  cursor = checkAddPage(doc, cursor, 30, config)

  let boxContent = gatherColumnContent(column, report, 20)
  const height = drawBox(doc, boxContent, startX, cursor, tableLimit, 20)
  cursor += height + 10

  return cursor
}

function gatherColumnContent(column, report, indent) {
  let content = ''
  const indentation = ' '.repeat(indent)

  if (!column) return content

  if (!column.key && column.columns && column.columns.length > 0) {
    content += `${column.label}\n`
    column.columns.forEach(subColumn => {
      content += gatherSubItemContent(subColumn, report, indent)
    })
  } else {
    content += gatherSubItemContent(column, report, indent)
  }

  return content
}

function gatherSubItemContent(column, report, indent) {
  let content = ''
  const indentation = ' '.repeat(indent)

  if (!column) return content

  if (!column.key && column.columns && column.columns.length > 0) {
    content += gatherSubItemContent(column.columns, report, indent)
  }

  switch (column.type) {
    case 'selectionOption':
      if (report[column.key] == '1') {
        content += `${indentation}${column.label}\n`
        if (column.columns && column.columns.length > 0) {
          column.columns.forEach(subColumn => {
            if (subColumn.columns && subColumn.columns.length > 0) {
              subColumn.columns.forEach(subSubColumn => {
                content += gatherSubItemContent(
                  subSubColumn,
                  report,
                  indent + 4,
                )
              })
            } else {
              content += gatherSubItemContent(subColumn, report, indent + 2)
            }
          })
        }
      }
      break
    case 'simpleInput':
      if (report[column.key]) {
        content += `${indentation}${column.label} ${report[column.key]}\n`
      }
      break
    case 'selection':
      if (report[column.key]) {
        content += `${column.label}\n`
        if (column.columns && column.columns.length > 0) {
          column.columns.forEach(subColumn => {
            content += gatherSubItemContent(subColumn, report, indent + 2)
          })
        }
      }
      break
    case 'checkbox':
      if (report[column.key] === '1') {
        content += `${indentation}${column.label}\n`
      }
      break
    default:
      if (report[column.key] !== '0' && report[column.key]) {
        content += `${indentation}${column.label} ${report[column.key]}\n`
      }
  }

  return content
}

function drawBox(doc, text, x, y, width, minHeight) {
  text = text.replace(/<[^>]+>/g, '')

  const lines = doc.splitTextToSize(text, width - 4)
  const lineHeight = doc.getTextDimensions('M').h
  let height = Math.max(lineHeight * lines.length + 4, minHeight)

  if (lines.length > 5) {
    height += 15
  }

  if (lines.length > 10) {
    height += 20
  }

  doc
    .setDrawColor(0)
    .setFillColor(255, 255, 255)
    .rect(x, y, width, height, 'S')
    .text(lines, x + 2, y + lineHeight)

  return height
}

function proximaVisitaAssinaturas(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    tableLimit,
    heightLimit,
    margin,
    startX,
    smallGutterY,
    line,
    smallBoxHeight,
  } = config

  cursor += config.boxHeight
  if (cursor + boxHeight * 7 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  cursor += boxHeight * 2

  const produtor = new Image()

  if (relatorio.assinaturaProdutorOuResponsavel) {
    if (relatorio.assinaturaProdutorOuResponsavel === '1') {
      produtor.src = relatorio.assinaturaProdutor
      doc.addImage(
        produtor,
        'PNG',
        startX + 50,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    } else {
      produtor.src = relatorio.assinaturaResponsavel
      doc.addImage(
        produtor,
        'PNG',
        startX + 50,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    }

    const consultor = new Image()
    consultor.src = relatorio.assinaturaConsultor

    if (relatorio.assinaturaConsultor) {
      doc.addImage(
        consultor,
        'PNG',
        tableLimit - 180,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    }

    doc.rect(startX, cursor + 100, tableLimit / 2.1, line, 'F')

    if (relatorio.assinaturaProdutorOuResponsavel === '1') {
      doc.text(
        relatorio.fazenda.produtor.Nome,
        tableLimit / 3.5,
        cursor + 100 + smallGutterY,
        null,
        null,
        'center',
      )
      doc.text(
        'Assinatura produtor(a)',
        tableLimit / 3.5,
        cursor + 115 + smallGutterY,
        null,
        null,
        'center',
      )
    } else {
      doc.text(
        relatorio.nomeResponsavel || 'Não informado',
        tableLimit / 3.5,
        cursor + 100 + smallGutterY,
        null,
        null,
        'center',
      )
      doc.text(
        'Assinatura do responsável',
        tableLimit / 3.5,
        cursor + 115 + smallGutterY,
        null,
        null,
        'center',
      )
    }

    doc.rect(startX + 280, cursor + 100, tableLimit / 2.1, line, 'F')
    doc.text(
      relatorio.consultor.pessoafisica.Nome,
      startX + 420,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura consultor(a)',
      startX + 420,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  } else {
    produtor.src = relatorio.assinaturas.assinaturaProdutor
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )

    const consultor = new Image()
    consultor.src = relatorio.assinaturas.assinaturaConsultor
    if (relatorio.assinaturas.assinaturaConsultor) {
      doc.addImage(
        consultor,
        'PNG',
        tableLimit - 180,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    }

    doc.rect(startX + 25, cursor + 100, tableLimit / 3, line, 'F')
    doc.text(
      relatorio.assinaturas.NomeResponsavel
        ? relatorio.assinaturas.NomeResponsavel
        : relatorio.fazenda.produtor.Nome,
      startX + 65,
      cursor + 100 + smallGutterY,
    )
    doc.text(
      'Assinatura ' +
        (relatorio.assinaturas.NomeResponsavel
          ? 'do responsável'
          : 'do(a) produtor(a)'),
      startX + 65,
      cursor + 115 + smallGutterY,
    )

    doc.rect(startX + 320, cursor + 100, tableLimit / 3, line, 'F')
    doc.text(
      relatorio.consultor.pessoafisica.Nome,
      startX + 360,
      cursor + 100 + smallGutterY,
    )
    doc.text(
      'Assinatura consultor(a)',
      startX + 360,
      cursor + 115 + smallGutterY,
    )
  }

  cursor += boxHeight * 2

  return cursor
}

export default (data, layout) => {
  return new Promise(function (resolve, reject) {
    const MARGIN = 25
    const realStartY = 95

    const doc = new JsPDF({
      format: 'a4',
      compress: true,
      unit: 'pt',
    })

    const config = {
      startY: realStartY,
      startX: MARGIN,
      smallGutterY: 12,
      smallGutterX: 6,
      line: 0.3,
      tableLimit: doc.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: doc.internal.pageSize.height - MARGIN,
      margin: MARGIN,
      lineColor: '#000',
      boxHeight: 27,
      smallBoxHeight: 18,
      gutterX: 14,
      gutterY: 23,
      smallFontSize: 6.5,
      fontSize: 10,
      largefontSize: 16,
      mediumFontSize: 12,
      logo: 'img/' + `${ENV_APP.imgFileLogin}`,
      bodyStarY: realStartY + 25 * 8.5,
    }

    try {
      let cursor = 0
      header(doc, config, data)
      subHeader(doc, data, config)
      config.startY = config.startY + config.boxHeight + 120
      cursor = renderDocument(doc, config, data, cursor, layout)
      cursor = cursor + config.boxHeight * 2
      cursor = proximaVisitaAssinaturas(doc, data, config, cursor)

      config.startY = cursor + config.boxHeight - 3
      const pageCount = doc.internal.getNumberOfPages()

      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        baseBoard(
          doc,
          config,
          cursor + config.boxHeight,
          String(i) + ' de ' + String(pageCount),
        )
      }
      doc
        .save(
          `diagnostico-geral${data.fazenda.pessoajuridica.NomeFantasia}-${data.dataAtendimento}.pdf`,
          {
            returnPromise: true,
          },
        )
        .then(() => {
          return resolve(true)
        })
    } catch (err) {
      reject(err)
    }
  })
}
