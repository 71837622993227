<template>
  <v-card>
    <v-card-title style="text-align: left">Informações básicas</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" sm="8" lg="8" xl="8" class="col-bordered">
          <span class="key-title">Produtor(a): </span>
          <span class="value-title">{{ relatorio.fazenda.produtor.Nome }}</span>
        </v-col>
        <v-col cols="6" sm="2" lg="2" xl="2" class="col-bordered">
          <span class="key-title">Idade do produtor: </span>
          <span class="value-title">{{ idade }}</span>
        </v-col>
        <v-col cols="6" sm="2" lg="2" xl="2" class="col-bordered">
          <span class="key-title">Matrícula: </span>
          <span class="value-title">{{
            relatorio.fazenda.producer.Matricula || '-'
          }}</span>
        </v-col>
        <v-col cols="12" sm="9" lg="9" xl="9" class="col-bordered">
          <span class="key-title">Fazenda: </span>
          <span class="value-title">{{
            relatorio.fazenda.pessoajuridica.NomeFantasia
          }}</span>
        </v-col>
        <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
          <span class="key-title">Data: </span>
          <span class="value-title">{{ relatorio.dataAtendimento }}</span>
        </v-col>
        <v-col cols="12" sm="9" lg="9" xl="9" class="col-bordered">
          <span class="key-title">Cidade: </span>
          <span class="value-title">{{
            relatorio.cidadefazenda ? relatorio.cidadefazenda.Nome : '-'
          }}</span>
        </v-col>
        <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
          <span class="key-title">Horário do ínicio: </span>
          <span class="value-title">{{ relatorio.horaInicio }}</span>
        </v-col>
        <v-col cols="12" sm="9" lg="9" xl="9" class="col-bordered">
          <span class="key-title">Consultor(a): </span>
          <span class="value-title">{{
            relatorio.consultor.pessoafisica.Nome
          }}</span>
        </v-col>
        <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
          <span class="key-title">Horário do término: </span>
          <span class="value-title">{{ relatorio.horaTermino }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
export default {
  name: 'InformacoesGerais',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  computed: {
    idade() {
      const dataNascimento = this.relatorio.fazenda.produtor.DataNascimento

      if (!dataNascimento) return '-'

      const dataAtual = new moment()
      const idade = dataAtual.diff(dataNascimento, 'years')
      return idade
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
