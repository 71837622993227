<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else>
      <v-card-title style="justify-content: center; font-size: 24px">
        <v-row justify="center">
          <v-col cols="12">
            <v-row justify="center"> Diagnóstico Geral </v-row>
          </v-col>
          <v-col cols="12">
            <v-row justify="center">{{ relatorio.NumeroVisita }}ª visita</v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <InformacoesGerais :relatorio="relatorio" />
      <Layout :relatorio="relatorio" :layout-structure="layout" />
      <ProximaVisita :relatorio="relatorio" />
      <v-col cols="12">
        <v-row justify="center">
          <v-btn
            color="primary"
            :loading="dialogProgressPDF"
            :disabled="dialogProgressPDF"
            @click="
              () => {
                exportRel(relatorio, true)
              }
            "
          >
            <v-icon style="margin-right: 6px"> mdi-file-document </v-icon>
            <span style="font-weight: bold; font-size: 16px">Exportar PDF</span>
          </v-btn>
        </v-row>
      </v-col>
    </div>
  </v-card>
</template>

<script>
import ReportService from '../../../../services/api/reports'
import exportPDF from '../../../../services/reports/RelatorioPDFOrdenha'
import { exportReport, timeout } from '../../../../utils/report'
import InformacoesGerais from './InformacoesGerais'
import Layout from './layout'
import ProximaVisita from './ProximaVisita'

export default {
  components: {
    InformacoesGerais,
    ProximaVisita,
    Layout,
  },

  props: {
    codRelatorio: {
      type: String,
      default: () => '',
    },
    draft: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      dialogProgressPDF: false,
      relatorio: null,
      layout: null,
    }
  },
  mounted() {
    if (!this.codRelatorio) {
      this.$router.push({
        path: `/${this.$user.get().role}/relatorios/solidos`,
      })
    } else {
      this.getRelatorio()
    }
  },
  methods: {
    timeout,
    exportPDF,
    getRelatorio() {
      ReportService.getLayout({ id: 15 }).then(({ data }) => {
        this.api.get
          .relatorioStructure(this.codRelatorio, this.draft)
          .then(data => {
            this.relatorio = data
            this.loading = false
          })

        const layoutStructure = JSON.parse(data.dados)

        let result = layoutStructure.slice(0, layoutStructure.length - 1)
        result[0]['lines'] = result[0]['lines'].slice(
          4,
          result[0]['lines'].length,
        )

        this.layout = result
      })
    },
    async exportRel(relatorio, local) {
      if (!relatorio) relatorio = this.relatorio
      this.dialogProgressPDF = !!local

      await this.timeout(200)

      await exportReport({
        report: relatorio,
        exportReport: this.exportPDF,
        layout: this.layout,
      })

      this.dialogProgressPDF = false
    },
  },
}
</script>
