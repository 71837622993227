<template>
  <div>
    <span class="key-title">{{ selection.label }}</span>
    <v-row v-for="column in selection.columns" :key="column.key">
      <v-col
        v-if="column.type == 'selectionOption' && relatorio[column.key] == '1'"
        class="col-bordered py-4 px-4 ml-4 mr-2"
      >
        <span v-if="!column?.columns?.length" class="value-title">{{
          column.label
        }}</span>
        <Selection
          v-if="column?.columns?.length && relatorio[`${column.key}`] == '1'"
          :relatorio="relatorio"
          :selection="column"
        />
      </v-col>
      <v-col
        v-else-if="column.type === 'simpleInput' && relatorio[column.key]"
        class="col-bordered py-4 px-4 ml-4 mr-2"
      >
        <span class="value-title" v-html="column.label"></span>
        <span v-if="relatorio[column.key] !== '1'" class="value-title">{{
          relatorio[column.key]
        }}</span>
      </v-col>
      <v-col
        v-else-if="column.type === 'selection' && relatorio[column.key]"
        class="col-bordered py-4 px-4 ml-4 mr-2"
      >
        <span class="value-title">{{ column.value }}</span>

        <Selection
          v-if="column?.columns?.length"
          :relatorio="relatorio"
          :selection="column"
        />
      </v-col>
      <v-col
        v-else-if="relatorio[column.key] !== '0' && relatorio[column.key]"
        class="col-bordered py-4 px-4 ml-4 mr-2"
      >
        <span class="value-title">{{ column.label }}</span>
        <span class="value-title">{{ column.value }}</span>

        <Selection
          v-if="column?.columns?.length"
          :relatorio="relatorio"
          :selection="column"
        />
      </v-col>

      <Selection
        v-if="selection?.columns?.length && !column['key']"
        :relatorio="relatorio"
        :selection="column"
      />
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Selection',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },

    selection: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {}
  },

  created() {},

  methods: {
    convertProps(props) {
      if (!props) return '12'

      const listProps = props.split(' ')

      let sizeColumns = listProps.find(item => item[0] === 'c')

      if (!sizeColumns) return ''

      sizeColumns = sizeColumns.replace('c', '')
      return Math.round(12 * (sizeColumns / 100))
    },

    formatLabel(label) {
      return label
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
